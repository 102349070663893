.score-container {
    position: relative;
  }
  
  .circle-ring {
    position: relative;
  }
  
  .svg {
    transform: rotate(-90deg);
  }
  
  .circle-gap {
    fill: transparent;
  }
  
  .red-circle {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: red; /* Change color as per your requirement */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .centered-text {
    font-size: 24px;
    color: white; /* Change color as per your requirement */
  }
  