.speed-msg{
    width: 100vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #33CB98;
    font-size: 3vh;
    font-weight: 700;
}

.speed-container{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1vw;
}

.pop-up-container{
    height: 50vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.pop-up-card{
    height: 30vh;
    width: 30vw;
    background-color: #33CB98;
    margin: 8vw;
    border-radius: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vw;
}

.prompt-msg{
    color: #FFFFFF;
    font-size: 4vh;
}

@media (max-width: 768px) { 

    .pop-up-container{
        height: 50vh;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .pop-up-card{
        height: 30vh;
        width: fit-content;
        background-color: #33CB98;
        margin: 8vw;
        border-radius: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2vw;
    }
    
}