.result-container{
    position: absolute;
    top:0;
    left:0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; 
  }
  
  .nav-button{
    color: #3C4D57;
    text-align: center;
    font-family: Inter;
    font-size: 16.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 16.5px */
    margin-top: 1vh;
  }
    
  .score-container{
    margin: 3vh 0;
    height: 15vh;
  }
  
  .red-circle {
    width: 100px; 
    height: 100px; 
    border-radius: 50%;
    background-color: var(--Colour-Green-500, #1BAE81);;
    text-align: center;
    line-height: 100px;
    font-size: 24px; 
    color: white;
    margin: 0 auto; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .circle-gap{
    width: 122.1px;
    height: 122.1px;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
  
  .circle-ring{
    width: 138.1px;
    height: 138.1px;
    background-color: #1BAE81;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
  }
  
  
  
  
  .title{
    color: #1D1D1F;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 155.556% */
  }
  
  .sub-title{
    color: var(--Colour-Green-500, #1BAE81);
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 200% */
  }
  
  .congras{
    width: 98vw;
    max-width: 586px;
    height: fit-content;
    flex-shrink: 0;
    background-color: #FAFAFA;
    color: #79787E;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    line-height: 22px; /* 183.333% */
    padding: 2vh 1vw;
    text-align: justify;
  }
  
  .reading-container{
      width: 100vw;
      /* width: fit-content; */
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3vh 0;
  }
  
  .reading{
    width: 355.564px;
    height: 146.067px;
    flex-shrink: 0;
    border-radius: 15px;
    border: 1px solid rgba(5, 12, 30, 0.07);
    background: #FFF;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.06);
    margin-top: 1vh;
  }
  
  .reading-title{
    position: absolute;
    margin-top: 2.5vh;
    margin-left: 2.5vh;
    color: #192853;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
  }
  
  .reading-value{
    position: absolute;
    margin-top: 6.5vh;
    margin-left: 3.5vh;
    color: var(--Colour-Green-500, #1BAE81);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .reading-unit{
    position: absolute;
    margin-top: 10vh;
    margin-left: 3.3vh;
    color: #767D93;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .reading-range{
    position: absolute;
    margin-top: 7vh;
    margin-left: 7rem;
  }
  
  .reading-threshold{
    color: #616A7B;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .reading-head{
    color: #616A7B;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
  }
  
  .low{
    position: absolute;
    margin-top: 3rem;
    margin-left: 8.5rem;
  }
  .normal{
    position: absolute;
    margin-top: 3rem;
    margin-left: 12.7rem;
  }
  .high{
    position: absolute;
    margin-top: 3rem;
    margin-left: 17.7rem;
  }
  .concerning{
    position: absolute;
    margin-top: 3rem;
    margin-left: 12rem;
  }
  
  .lows{
    position: absolute;
    margin-top: 3rem;
    margin-left: 8.1rem;
  }
  
  .normals{
    position: absolute;
    margin-top: 3rem;
    margin-left: 10.4rem;
  }
  
  .aboves{
    position: absolute;
    margin-top: 3rem;
    margin-left: 14.1rem;
  }
  
  .highs{
    position: absolute;
    margin-top: 3rem;
    margin-left: 18.4rem;
  }
  
  .bpm-one{
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 7rem;
  }
  
  .bpm-two{
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 11.5rem;
  }
  .bpm-three{
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 16.2rem;
  }
  .bpm-four{
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 19.7rem;
  }
  
  .bp-one{
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 7rem;
  }
  
  .bp-two{
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 10rem;
  }
  .bp-three{
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 13.2rem;
  }
  .bp-four{
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 16.7rem;
  }
  .bp-five{
    position: absolute;
    margin-top: 5.2rem;
    margin-left: 19.7rem;
  }
  .units{
    font-size: 0.6rem;
  }
  .download-button{
    margin-top: 6vh;
    width: 355.564px;
    height: 65.146px;
    flex-shrink: 0;
    border-radius: 15px;
    background: #33CB98;
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    border: 0;
  }
  
  .diastolic{
    position: absolute;
    margin-top: 12vh;
    width: 43.3vh;
  }
  
  @media (max-width: 768px) { 
    .reading-container{
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 3vh 0;
    }

  }