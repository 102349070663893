.webcam {
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
  /* object-fit:fill; */
  object-fit: cover; /* Use object-fit: cover; or object-fit: contain; */
}

.instructions-back-btn {
  border: 0px solid blue;
  cursor: pointer;
}

.parent-container {
  display: flex;
  border: 0px solid red;
  flex-direction: column;
  height: 100vh;
}

.instructions-div {
  display: flex;
  border: 0px solid green;
  z-index: 2;
  margin-left: 2vw;
  margin-top: 2vh;
}

.progress-card {
  display: flex;
  flex-direction: column;
  align-self: center;
  border: 0px solid green;
  width: 30vw;
  z-index: 2;
  border-radius: 20;
  padding: 15;
  position: absolute;
  bottom: 5vh;
  opacity: 0.7;
  background-color: white;
}

.percent-completed-font {
  border: 0px solid red;
  font-weight: 600;
}

.capture-btn {
  display: flex;
  flex-direction: column;
  align-self: center;
  border: 0px solid green;
  text-align: center;
  z-index: 2;
  border-radius: 20;
  padding: 15;
  position: absolute;
  bottom: 5vh;
  background-color: white;
}

.cancel-scan-div {
  display: flex;
  border: 0px solid red;
  color: red;
  justify-content: right;
}

.start-btn {
  display: "flex";
  flex-direction: "column";
  align-items: "center";
  border: "0px solid green";
  text-align: "center";
  z-index: 2;
  border-radius: 20;
  padding: 15;
  position: "absolute";
  bottom: "5vh";
  background-color: "white";
}

.circular-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 2px solid white;
}

.background-container {
  background-color: white;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.circular-hole {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  border-radius: 0%;
  background: radial-gradient(circle at center, transparent 250px, white 270px);
  z-index: 1;
}

.alert {
  border: 0px solid blue;
  display: flex;
  width: 100vw;
  font-size: 2vh;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  position: absolute;
}

@media (max-width: 768px) {
  .progress-card {
    display: flex;
    flex-direction: column;
    align-self: center;
    border: 0px solid green;
    width: 70vw;
    z-index: 2;
    border-radius: 20;
    padding: 15;
    position: absolute;
    bottom: 5vh;
    opacity: 0.7;
    background-color: white;
  }
  .circular-hole {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    border-radius: 0%;
    background: radial-gradient(
      ellipse at center,
      transparent 128px,
      white 132px
    );
    z-index: 1;
  }
}

.face-guide-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.guide-circle {
  position: relative;
  width: 16rem;
  height: 16rem;
}

.circle-border {
  position: absolute;
  inset: 0;
  border-radius: 9999px;
  border: 3px solid;
}

.circle-border-static {
  border-color: rgba(59, 130, 246, 0.3);
  overflow: hidden;
}

.circle-border-pulse {
  border-color: rgb(59, 130, 246);
  animation: pulse 2s infinite;
}

.scanning-line-vertical {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #60a5fa, transparent);
  animation: scanVertical 2s ease-in-out infinite;
  top: 0;
}

.scanning-line-horizontal {
  position: absolute;
  top: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #60a5fa, transparent);
  animation: scanHorizontal 2s ease-in-out infinite;
  left: 50%;
  transform: translateX(-50%);
}

.corner-marker {
  position: absolute;
  height: 0.75rem;
  width: 2px;
  background-color: rgba(59, 130, 246, 0.7);
  transform-origin: bottom;
  top: 2px;
  left: 50%;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

@keyframes scanHorizontal {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(256px);
  }
}

@keyframes scanVertical {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(256px);
  }
}
